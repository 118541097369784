import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import gitLogo from "./github.svg"
import linkedLogo from "./linkedin.svg"
import resumeLogo from "./resume.svg"
import resume from "../pages/MatthewsResume.pdf"
import {BrowserView, MobileView} from "react-device-detect";

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#479099`,
      marginBottom: `1.45rem`,
    }}
  >
    <BrowserView>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1000,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginBottom: `1.50rem`
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <p style={{ margin: 0 }}>
        
      <a
          href={resume}
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {resumeLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
          Resume
        </a>
        <a
          href="https://github.com/matthew-pham"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {gitLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
          matthew-pham
        </a>

        <a
          href="https://www.linkedin.com/in/matthewdpham/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {linkedLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
          matthewdpham
        </a>

        <a
          href="https://github.com/KevinAndMatthewsProjects"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {gitLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
          KevinAndMatthewsProjects
        </a>
      </p>
    </div>
    </BrowserView>
    <MobileView>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1000,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginBottom: `1.50rem`
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <p style={{ margin: 0 }}>
        
      <a
          href={resume}
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {resumeLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
        </a>
        <a
          href="https://github.com/matthew-pham"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {gitLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
        </a>

        <a
          href="https://www.linkedin.com/in/matthewdpham/"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {linkedLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
        </a>

        <a
          href="https://github.com/KevinAndMatthewsProjects"
          style={{
            color: `white`,
            textDecoration: `none`,
            marginRight: `1.00rem`,
            marginTop: `50rem`
          }} target="_blank"
        >
        <img src = {gitLogo}
        style={{
          maxWidth: 20,
          marginRight: `0.2rem`,
          marginTop: `1rem`
          
        }}
      
      ></img>
        </a>
      </p>
    </div>
    </MobileView>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
